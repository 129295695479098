// @flow weak

import React from "react"
import { styled } from "@material-ui/core/styles"
import * as colors from "@material-ui/core/colors"

const Container = styled("div")({
  fontSize: 24,
  fontWeight: "bold",
  color: colors.grey[700],
  paddingTop: 32,
  paddingBottom: 32
})

export default () => {
  return <Container>CollegeAI API Manager</Container>
}
