// @flow weak

import React, {
  useState,
  useContext,
  useEffect,
  createContext,
  useMemo
} from "react"
import { useLocalStorage } from "react-use"
import bent from "bent"
import useEventCallback from "use-event-callback"

const getJSON = bent("json", "GET", 200, window.location.origin)

const InfoContext = createContext()

export const useInfo = () => useContext(InfoContext)

const InfoProvider = ({ children }) => {
  const [info, changeInfo] = useState(null)
  const [token, changeToken] = useLocalStorage("token")

  const reloadInfo = useEventCallback(() => changeInfo(null))

  useEffect(() => {
    async function doAsync() {
      if (info === null && token) {
        const info = await getJSON("/api/myinfo", null, {
          Authorization: "Bearer " + token
        }).catch(e => {
          console.log("failed to authenticate with token", e.toString())
          changeToken(null)
          return null
        })
        if (info) {
          changeInfo(info)
        }
      }
    }
    doAsync()
  }, [info, token])

  const infoContextValue = useMemo(
    () => ({ ...info, token, reloadInfo, changeToken }),
    [info, token]
  )

  return (
    <InfoContext.Provider value={infoContextValue}>
      {children}
    </InfoContext.Provider>
  )
}

export default InfoProvider
