var stripe = window.Stripe(
  process.env.REACT_APP_STRIPE_PK || "pk_test_PtdhKowIg8qGWAeYSDYXPagq"
)
window.inStripeTestMode = process.env.REACT_APP_STRIPE_PK ? false : true

window.goToStripeCheckout = (product, email) => {
  stripe.redirectToCheckout({
    items: [{ plan: `stripe_${product}`, quantity: 1 }],
    successUrl: `${window.location.origin}/?payment=success&product=stripe_${product}`,
    cancelUrl: `${window.location.origin}/?payment=cancelled&product=stripe_${product}`,
    customerEmail: email
  })
}
