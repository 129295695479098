// @flow weak

import React, { useState } from "react"
import { styled } from "@material-ui/core/styles"
import SectionContainer from "../SectionContainer"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import * as colors from "@material-ui/core/colors"
import bent from "bent"
import { useInfo } from "../InfoProvider"

const postJSON = bent("json", "POST", window.location.origin)

const Title = styled("div")({})
const StyledButton = styled(Button)({
  marginTop: 16,
  textTransform: "none"
})

export default () => {
  const { email, changeToken, billing } = useInfo()
  const [fields, changeFields] = useState({})
  const [error, changeError] = useState()
  const changeField = field => e => {
    changeFields({ ...fields, [field]: e.target.value })
  }

  const signIn = async () => {
    const response = await postJSON("/api/signin", fields).catch(e => {
      changeError(e.toString())
      return null
    })
    if (!response) return
    changeToken(response.token)
  }

  const signUp = async () => {
    if (fields.password !== fields.password2) {
      return changeError("Passwords do not match")
    }
    if (!fields.orgName)
      return changeError("Organization/Individual Name is Required")
    if (!fields.email) return changeError("Email is Required")
    if (!fields.password) return changeError("Password is Required")
    if (!fields.userAgreement)
      return changeError("Must agree to User Agreement")

    changeError(null)
    const response = await postJSON("/api/signup", fields).catch(e => {
      changeError(e.toString())
      return null
    })
    if (!response) return
    changeToken(response.token)
  }

  return (
    <SectionContainer title="Login or Sign Up">
      {error && (
        <Box
          margin={2}
          padding={2}
          style={{ backgroundColor: colors.red[100] }}
          fontWeight="bold"
          color={colors.red[600]}
        >
          {error}
        </Box>
      )}
      {!email ? (
        <Box display="flex">
          <Box display="flex" flexDirection="column" flexGrow={1} padding={2}>
            <Title>Login</Title>
            <TextField
              variant="outlined"
              label="Email"
              margin="normal"
              onChange={changeField("email")}
            />
            <TextField
              variant="outlined"
              label="Password"
              margin="normal"
              type="password"
              onChange={changeField("password")}
            />
            <StyledButton variant="outlined" onClick={signIn}>
              Login
            </StyledButton>
          </Box>
          <Box display="flex" flexDirection="column" flexGrow={1} padding={2}>
            <Title>Sign Up</Title>
            <TextField
              variant="outlined"
              required
              label="Email"
              margin="normal"
              onChange={changeField("email")}
            />
            <TextField
              variant="outlined"
              required
              label="Password"
              margin="normal"
              type="password"
              onChange={changeField("password")}
            />
            <TextField
              variant="outlined"
              required
              label="Confirm Password"
              margin="normal"
              type="password"
              onChange={changeField("password2")}
            />
            <TextField
              variant="outlined"
              required
              label="Organization or Person Name"
              margin="normal"
              onChange={changeField("orgName")}
            />
            <TextField
              variant="outlined"
              label="Organization Website (if applicable)"
              margin="normal"
              onChange={changeField("orgSite")}
            />
            <Box display="flex" alignItems="center">
              <Checkbox
                onChange={(e, value) =>
                  changeField("userAgreement")({ target: { value } })
                }
              />{" "}
              I agree to the&nbsp;
              <a href="/legal.txt">user agreement</a>
            </Box>
            <StyledButton onClick={signUp} variant="outlined">
              Sign Up
            </StyledButton>
          </Box>
        </Box>
      ) : (
        <Box padding={4}>
          You are logged in as {email}
          <br />
          <br />
          You can email support at <b>support@collegeai.com</b>.
          {(!billing ||
            (billing && !billing.billing_type.includes("premium"))) && (
            <div style={{ color: colors.grey[600], marginTop: "1em" }}>
              {" For fast email support, upgrade to Full Commercial."}
            </div>
          )}
          <br />
          <StyledButton
            variant="outlined"
            onClick={() => {
              window.localStorage.clear()
              window.location.reload()
            }}
          >
            Sign Out
          </StyledButton>
        </Box>
      )}
    </SectionContainer>
  )
}
