// @flow weak

import React, { useState } from "react"
import { styled } from "@material-ui/core/styles"
import SimpleDialog from "../SimpleDialog"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import Box from "@material-ui/core/Box"
import { useInfo } from "../InfoProvider"
import bent from "bent"

const postJSON = bent("json", "POST", 200, window.location.origin)

export default ({ open, onClose }) => {
  const { token, reloadInfo } = useInfo()
  const [requestContent, changeRequestContent] = useState("")
  const [userAgreement, changeUserAgreement] = useState(false)
  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      title="Apply for Educational API License"
      actions={[
        {
          text: "Apply for Educational API License",
          disabled: userAgreement !== true,
          onClick: async () => {
            const response = await postJSON(
              "/api/freeapply",
              {
                request_content: requestContent,
                metadata: { userAgreement }
              },
              {
                Authorization: "Bearer " + token
              }
            ).catch(e => (alert(e.toString()), null))
            if (!response) return
            onClose()
            reloadInfo()
          }
        }
      ]}
    >
      <TextField
        rows="5"
        variant="outlined"
        margin="normal"
        multiline
        label="What do you plan on using the CollegeAI API for? (2-4 sentences)"
        onChange={e => changeRequestContent(e.target.value)}
        fullWidth
      />
      <Box display="flex" alignItems="center">
        <Checkbox onChange={(e, checked) => changeUserAgreement(checked)} /> I
        understand my API Key may not be used for commercial purposes
      </Box>
    </SimpleDialog>
  )
}
