// @flow weak

import React, { useState, useEffect } from "react"
import { styled } from "@material-ui/core/styles"
import SectionContainer from "../SectionContainer"
import Grid from "@material-ui/core/Grid"
import * as colors from "@material-ui/core/colors"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import ApplyForFreeDialog from "../ApplyForFreeDialog"
import SimpleDialog from "../SimpleDialog"
import { useInfo } from "../InfoProvider"
import bent from "bent"
import queryString from "query-string"

const postJSON = bent("json", "POST", window.location.origin)

const PlansContainer = styled("div")({
  display: "flex",
  margin: 16,
  border: `1px solid ${colors.grey[400]}`,
  width: "100%"
})
const PlanContainer = styled("div")({
  borderRight: `1px solid ${colors.grey[300]}`,
  padding: 16,
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
})
const PlanTitle = styled("div")({
  fontSize: 18,
  fontWeight: "bold",
  textAlign: "center",
  margin: 16
})
const PlanPrice = styled("div")({
  textAlign: "center",
  fontSize: 24,
  fontWeight: "bold",
  padding: 16,
  margin: 16,
  color: colors.grey[700]
})
const PlanBullets = styled("ul")({})
const PlanBullet = styled("li")({})
const PlanButtonContainer = styled("div")({
  textAlign: "center",
  paddingBottom: 16
})
const PlanButton = styled(Button)({
  textTransform: "none"
})
const GuaranteeText = styled("div")({
  color: colors.grey[600],
  padding: 16,
  "& a": {
    color: colors.blue[400]
  }
})

export default () => {
  const { email, application, reloadInfo, billing, token } = useInfo()
  const [error, changeError] = useState(null)
  const [freeDialogOpen, changeFreeDialogOpen] = useState(false)

  const plans = [
    {
      name: "Education / Non-commercial",
      price: "Free",
      bullets: [
        "Non-commercial use",
        "Must attribute CollegeAI on page",
        "Limited API Access",
        "Approval in 1-2 weeks"
      ],
      action: application ? application.status : "Apply",
      disabled: Boolean(application)
    },
    {
      name: "Limited Commercial",
      price: "$120 / yr",
      bullets: [
        "For small organizations",
        "Must attribute CollegeAI on page",
        "Full API Access"
      ],
      disabled: Boolean(billing),
      action:
        billing && billing.billing_type.includes("basic")
          ? "Purchased"
          : "Purchase"
    },
    {
      name: "Full Commercial",
      price: "$600 / yr",
      bullets: [
        "For midsize to large organizations",
        "No attribution required",
        "Email support",
        "Full API Access"
      ],
      disabled: billing && billing.billing_type.includes("premium"),
      action:
        billing && billing.billing_type.includes("basic")
          ? "Upgrade"
          : billing && billing.billing_type.includes("premium")
          ? "Purchased"
          : "Purchase"
    }
  ]

  const onClickPlan = plan => {
    if (!email) {
      changeError("You must sign up or log in before applying/purchasing.")
      return
    }
    if (plan.price === "Free") {
      changeFreeDialogOpen(true)
    } else if (plan.name === "Limited Commercial") {
      window.goToStripeCheckout("annual_basic_2020", email)
    } else if (plan.name === "Full Commercial") {
      window.goToStripeCheckout("annual_premium_2020", email)
    } else {
      alert("Error understanding plan")
    }
  }

  useEffect(() => {
    const qs = queryString.parse(window.location.search)
    if (qs.payment === "success" && qs.product) {
      postJSON(
        "/api/subscribe",
        { product: qs.product.replace(/stripe_/g, "") },
        { Authorization: "Bearer " + token }
      ).then(() => {
        window.location.href = "/"
      })
    }
  }, [])

  return (
    <SectionContainer title="Billing">
      <Grid container>
        <PlansContainer>
          {plans.map((plan, plani) => (
            <Grid key={plan.name} xs={4} item>
              <PlanContainer
                style={{
                  borderRight: plani === plans.length - 1 ? "none" : undefined
                }}
              >
                <PlanTitle>{plan.name}</PlanTitle>
                <PlanPrice>{plan.price}</PlanPrice>
                <PlanBullets>
                  {plan.bullets.map(b => (
                    <PlanBullet key={b}>{b}</PlanBullet>
                  ))}
                </PlanBullets>
                <Box paddingTop={2} flexGrow={1} />
                <PlanButtonContainer>
                  <PlanButton
                    disabled={Boolean(plan.disabled)}
                    onClick={() => onClickPlan(plan)}
                    variant="outlined"
                  >
                    {plan.action}
                  </PlanButton>
                </PlanButtonContainer>
              </PlanContainer>
            </Grid>
          ))}
        </PlansContainer>
      </Grid>
      <GuaranteeText>
        <b>All paid plans have a 14 day money-back guarantee.</b> Some
        restrictions apply, see{" "}
        <a href="https://api.collegeai.com/v1/docs/legal/terms-of-service">
          terms of service
        </a>{" "}
        for details.
      </GuaranteeText>
      <ApplyForFreeDialog
        open={freeDialogOpen}
        onClose={() => changeFreeDialogOpen(false)}
      />
      <SimpleDialog
        open={Boolean(error)}
        title="Error"
        onClose={() => changeError(null)}
        red
      >
        {error}
      </SimpleDialog>
    </SectionContainer>
  )
}
