// @flow weak

import React from "react"
import { styled } from "@material-ui/core/styles"
import * as colors from "@material-ui/core/colors"

const Container = styled("div")({
  border: `2px solid ${colors.grey[300]}`,
  borderRadius: 3,
  backgroundColor: "#fff",
  marginTop: 16,
  marginBottom: 16,
  minHeight: 200,
  padding: 16,
  boxShadow: "0px 1px 3px rgba(0,0,0,0.05)"
})
const Title = styled("div")({
  fontWeight: "bold",
  color: colors.grey[500],
  marginBottom: 16
})

export default ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  )
}
