// @flow weak

import React from "react"
import { styled } from "@material-ui/core/styles"
import SectionContainer from "../SectionContainer"
import { useInfo } from "../InfoProvider"
import * as colors from "@material-ui/core/colors"

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  minHeight: 150
})
const APIKey = styled("code")({
  margin: 16,
  padding: 24,
  backgroundColor: colors.grey[200],
  minWidth: 400,
  textAlign: "center"
})

export default () => {
  const { apiKeys, email } = useInfo()

  return (
    <SectionContainer title="API Key">
      <Container>
        {!email ? (
          <APIKey>Login and Select Plan to get API Key</APIKey>
        ) : apiKeys.length === 0 ? (
          <APIKey>Select a Plan to Get an API Key</APIKey>
        ) : (
          apiKeys.map(apiKey => (
            <APIKey key={apiKey.key_string}>{apiKey.key_string}</APIKey>
          ))
        )}
      </Container>
    </SectionContainer>
  )
}
