// @flow weak

import React from "react"
import { useEffect, useState } from "react"
import { styled } from "@material-ui/core/styles"
import * as colors from "@material-ui/core/colors"

import InfoProvider from "./components/InfoProvider"
import Header from "./components/Header"
import LoginBox from "./components/LoginBox"
import BillingBox from "./components/BillingBox"
import APIKeysBox from "./components/APIKeysBox"

const Container = styled("div")({
  backgroundColor: colors.grey[200],
  padding: 16,
  display: "flex",
  justifyContent: "center",
  minHeight: "100vh"
})
const InnerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxWidth: 1200,
  boxSizing: "border-box",
  width: "100%"
})

export default () => {
  return (
    <InfoProvider>
      <Container>
        <InnerContainer>
          <Header />
          <LoginBox />
          <BillingBox />
          <APIKeysBox />
        </InnerContainer>
      </Container>
    </InfoProvider>
  )
}
